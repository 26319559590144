import react from 'react';
import styled from 'styled-components';
import { Grid2, Typography } from '@mui/material';

import { DetailScaffold, FormScaffold } from '../..';

const StyledText = styled(Typography)`
    font-size: 28px !important;
    color: #fff !important;
`;

const StyledTitle = styled(StyledText)`
    font-size: 33px !important;
    font-weight: 700 !important;
`;

const StyledDisclaimerText = styled(Typography)`
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #fff !important;
    text-align: center;
`;

function FormView() {
    return (
        <Grid2
            container
            direction="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            gap={5}
            sx={{
                padding: {
                    xs: '20px 0',
                    md: '100px 0'
                }
            }}
        >
            <Grid2 size={{ xs: 11, md: 10 }}>
                <FormScaffold />
            </Grid2>
        </Grid2>
    );
}

export default FormView;
