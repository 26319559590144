import React from 'react';
import styled from 'styled-components';
import { Grid2, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { filter, some } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const InputDecoration = styled(Grid2)`
    height: 100%;
    background: #013462;
    padding: 20px;
    display: ${(props) => (props.$hideDecoration ? 'none' : 'block')} !important;
`;

const StyledInput = styled.input`
    height: 100%;
    padding: ${(props) => props.$padding};
    width: 100%;
    border: unset !important;
    box-sizing: border-box;
    outline: none;
    background: ${(props) => (props.$hideDecoration ? '#fff' : '#edf1f6')} !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    text-align: ${(props) => props.$textAlign} !important;
    pointer-events: ${(props) => (props.$inactive ? 'none' : 'auto')} !important;
    cursor: pointer;
    text-transform: uppercase;

    white-space: pre-wrap;
    text-overflow: ellipsis;

    &::placeholder {
        color: #000000 !important;
    }

    &:focus {
        border: none;
        outline: none;
    }

    &:focus::placeholder {
        color: #000000 !important;
        opacity: 0.5;
    }
`;

const StyledContainer = styled(Grid2)`
    position: relative;
    border-radius: ${(props) => (props.$hideDecoration ? (props.$rounderCorners ? '8px' : '0') : '8px')} !important;
    border: solid 1px rgba(0, 0, 0, 0.8);
    overflow: auto !important;
    cursor: pointer;
`;

const StyledMenuContainer = styled(Grid2)`
    position: absolute;
    z-index: 10; // Increased z-index to ensure it floats above other elements
    // top: 100%;
    // left: 0;
    width: ${(props) => (props.$width ? `${props.$width}px` : '0')};
    border: solid 1px rgba(0, 0, 0, 0.5);
    max-height: 30vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
`;

const StyledSelection = styled(Grid2)`
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background: #fff;
    text-align: left;
    // padding-left: 25%;

    &:hover {
        background: #d9d9d9 !important;
    }
`;

const DropDownIcon = styled(ArrowDropDown)`
    color: ${(props) => (props.$isFocused ? '#013462' : 'rgba(0,0,0,0.5)')};
    font-size: xx-large !important;
`;

function Input(props) {
    const {
        type = 'text',
        value,
        onChange = () => {},
        placeholder = '',
        hideDecoration = false,
        rounderCorners = false,
        textAlign = 'center',
        onBlur,
        selections,
        selector,
        error,
        padding = '12px 50px 10px 10px',
        ...properties
    } = props;
    const [isFocused, setIsFocused] = React.useState(false);
    const [_selections, setSelections] = React.useState(selections || []);
    const ref = React.useRef(null);
    const parentRef = React.useRef(null);

    React.useEffect(() => {
        if (value) {
            setSelections(filterData(selections, value || '', selector));
        } else {
            setSelections(selections);
        }
    }, [value]);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsFocused(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (!ref.current) {
                if (parentRef.current && !parentRef.current.contains(event.target)) {
                    setIsFocused(false);
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [parentRef]);

    React.useEffect(() => {
        if (!isFocused) {
            onBlur && onBlur();
        }
    }, [isFocused]);

    function filterData(dataArray, searchString = '', selector) {
        const lowerSearchString = String(searchString).toLowerCase();
        return filter(dataArray, (item) =>
            some(lowerSearchString.split(' '), (word) =>
                String(item[selector] || '')
                    .toLowerCase()
                    .includes(word)
            )
        );
    }

    return (
        <>
            <StyledContainer
                $hideDecoration={hideDecoration}
                $rounderCorners={rounderCorners}
                container
                padding={0}
                gap={0}
                position="relative"
                ref={parentRef}
            >
                <InputDecoration $hideDecoration={hideDecoration} size={{ xs: 2 }} />
                <Grid2 size="grow">
                    <StyledInput
                        $padding={padding}
                        $hideDecoration={hideDecoration}
                        type={type}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        $textAlign={textAlign}
                        onFocus={() => setIsFocused(true)}
                        {...properties}
                    />
                </Grid2>
                {selections && selector && (
                    <Grid2
                        display="flex"
                        alignItems="center"
                        height="100%"
                        position="absolute"
                        zIndex={1}
                        top={0}
                        right="7px"
                    >
                        <DropDownIcon $isFocused={isFocused} />
                    </Grid2>
                )}
            </StyledContainer>
            {error && (
                <Grid2 size={{ xs: 12 }}>
                    <Typography marginLeft="5px" fontSize="14px" color="error">
                        {error}*
                    </Typography>
                </Grid2>
            )}
            {(!props.disabled && isFocused && selections && selector && (
                <>
                    <StyledMenuContainer ref={ref} $width={parentRef?.current?.offsetWidth}>
                        {filterData(selections, value || '', selector).map((selection, index) => (
                            <StyledSelection
                                key={`${uuidv4()}-select-item-${index}`}
                                size={{ xs: 12 }}
                                onClick={() => {
                                    onChange({ target: { value: selection } });
                                    setIsFocused(false);
                                }}
                            >
                                {selection[selector]}
                            </StyledSelection>
                        ))}
                    </StyledMenuContainer>
                </>
            )) ||
                null}
        </>
    );
}

export default Input;
