function execute(url, data) {
    const myHeaders = new Headers();
    let raw = null;
    myHeaders.append('Content-Type', 'application/json');

    if (data) {
        raw = JSON.stringify(data);
    }

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        ...((!!raw && { body: raw }) || {}),
        redirect: 'follow'
    };

    async function run() {
        return fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => result)
            .catch((error) => console.error(error));
    }

    return run();
}

export default execute;
