import React from 'react';
import { MaintenanceService } from '../api';

export const AppContext = React.createContext();

/**
 * default form variables
 * - type
 * - company
 * - destination
 * - groupSize
 * - travellers
 * - plan
 * - from
 * - to
 */
export const AppProvider = ({ children }) => {
    const [state, setState] = React.useState({});
    const [maintenance, setMaintenance] = React.useState({});
    const [config, setConfig] = React.useState({
        page: 'landing'
    });
    const [loadingForm, setLoadingForm] = React.useState(false);

    const [form, setForm] = React.useState([]);

    React.useEffect(() => {
        if (!!(state?.totalCompanions || []).length) {
            const arr = Array(state.totalCompanions.length + 1).fill({});
            setForm(() => arr);
        } else {
            setForm(() => Array(1).fill({}));
        }
    }, [state.totalCompanions]);

    React.useEffect(() => {
        handleMaintenanceTables();
    }, []);

    async function handleMaintenanceTables() {
        try {
            if (loadingForm) return;
            setLoadingForm(true);

            const res = await MaintenanceService();
            const { data } = res;
            setLoadingForm(false);

            // handle companies
            const company = (data?.company || []).map((c) => ({
                label: c?.companyDesc,
                value: c?.companyCd
            }));

            // handle countries
            const country = (data?.country || [])
                .map((c) => ({
                    label: c?.countryName.toLowerCase().replace(/\b\w/g, function (char) {
                        return char.toUpperCase();
                    }),
                    value: c?.countryCd,
                    code: c?.bmCode
                }))
                .sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
            // handle plans
            const plans = (data?.travelPlan || []).map((p) => ({
                condition: p.travelTypeCd,
                label: p.travelPlanDesc,
                value: p.travelPlanCd
            }));

            // handle geoCoverage
            const geoCoverage = (data?.travelGeoCoverage || []).map((gc) => ({
                condition: gc.travelTypeCd,
                condition2: gc.travelPlanCd,
                label: gc.travelGeoCoverageDesc,
                value: gc.travelGeoCoverageCd
            }));

            // handle travel transport mode
            const transportMode = (data?.travelTransportMode || []).map((tm) => ({
                condition: tm.travelTypeCd,
                condition2: tm.travelPlanCd,
                condition3: tm.travelGeoCoverageCd,
                label: tm.travelTransportModeDesc,
                value: tm.travelTransportModeCd
            }));

            // handle max duration
            const maxTripDuration = (data?.travelDurationSelection || [])
                .filter((f) => !['U', 'V'].includes(f.durationTypeCd))
                .map((mtd) => ({
                    condition: mtd.durationTypeCd,
                    label: mtd.durationSelectionDesc,
                    value: {
                        durationTypeCd: mtd.durationTypeCd,
                        durationSelectionCd: mtd.durationSelectionCd,
                        durationLimit: mtd.durationLimit
                    }
                }));

            setMaintenance(() => ({ ...data, country, plans, geoCoverage, transportMode, maxTripDuration, company }));
        } catch (error) {
            setLoadingForm(false);
            console.error('Error fetching maintenanace tables', error);
        }
    }

    return (
        <AppContext.Provider value={{ state, setState, maintenance, config, setConfig, form, setForm, loadingForm, setLoadingForm }}>
            {children}
        </AppContext.Provider>
    );
};
