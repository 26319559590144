import React from 'react';
import { ButtonComponent, ControllerComponent, ModalComponent } from '../../';
import { Grid2, Typography, styled } from '@mui/material';
import { AppContext } from '../../../services';

const StyledText = styled(Typography)`
    font-size: 8px !important;
    margin: 0 !important;
    text-align: center !important;
`;

const StyledContainer = styled(Grid2)`
    padding: 25px 25px !important;
`;

const StyledSubText = styled(Typography)`
    font-size: 10x !important;
    margin: 0 !important;
    text-align: center !important;
`;

const StyledPrimaryText = styled(StyledSubText)`
    font-size: 14px !important;
    font-weight: 500 !important;
`;

const mappings = {
    F: 'FAMILY',
    G: 'GROUP',
    I: 'INDIVIDUAL',
    WC: 'WITH_COMPANION'
};

const allowedClients = {
    ALL: ['ADULT', 'MINOR'],
    Adult: ['ADULT']
};

const displayNameMappings = {
    ADULT: 'Adults',
    MINOR: 'Minor'
};

const initialState = {
    ADULT: 0,
    MINOR: 0
};

function TravellersModal(props) {
    const { open, handleClose = () => {} } = props;
    const { maintenance, state, setState } = React.useContext(AppContext);
    const [internalState, setInternalState] = React.useState(initialState);

    React.useEffect(() => {
        initialize();

        return () => {
            setInternalState(() => initialState);
        };
    }, [open]);

    function initialize() {
        const { ADULT = 0, MINOR = 0 } = state;

        setInternalState(() => ({ ADULT, MINOR }));
    }

    function handleSubmit() {
        setState((s) => ({ ...s, ...internalState }));
        handleClose();
    }

    function findAgeLimit(type, variant) {
        const config = (maintenance?.parameters || []).find(
            (f) => f.paramName === `CLIENT.CATEGORY.${type}.AGE.${variant}`
        );

        return config.paramValueN;
    }

    function handleGuideText() {
        let guide = '';
        switch (state?.groupSize) {
            case 'F':
                guide = 'Additional Travelers can include Up to 1 Adult and 3 minors';
                break;
            // case 'G':
            //     guide = 'Additional travelers can include up to 9 adults';
            //     break;
            // case 'WC':
            //     guide = 'An additional traveler can be either 1 adult or 1 minor';
            //     break;

            default:
                break;
        }

        return guide;
    }

    return (
        <ModalComponent
            open={open}
            handleClose={handleClose}
            sx={{
                maxWidth: {
                    xs: '80vw',
                    md: '30vw'
                },
                minWidth: {
                    xs: '95vw',
                    md: '30vw'
                },
                maxHeight: {
                    xs: '85vh',
                    md: '70vh'
                },
                minHeight: {
                    xs: '60vh',
                    md: '40vh'
                },
                overflow: {
                    xs: 'auto',
                    md: 'auto'
                }
            }}
        >
            <StyledContainer container rowSpacing={2}>
                <Grid2 size={{ xs: 12 }}>
                    <Grid2 container sx={{ flexGrow: 1 }}>
                        <>
                            <Grid2 container sx={{ flexGrow: 1 }} justifyContent="center">
                                <Grid2 size={{ xs: 12, md: 9 }}>
                                    <StyledText>{handleGuideText()}</StyledText>
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>
                </Grid2>

                {(maintenance?.parameters || [])
                    .filter((f) => f.paramName === `GROUP_SIZE.${mappings[state?.groupSize] || 69}.ALLOWED_CLIENT`)
                    .map((allowedClient) =>
                        (allowedClients[allowedClient.paramValueV || 69] || []).map((client) => {
                            const config = (maintenance?.parameters || []).find(
                                (f) => f.paramName === `GROUP_SIZE.${mappings[state?.groupSize] || 69}.${client}.LIMIT`
                            );

                            return (
                                <Grid2 size={{ xs: 12 }}>
                                    <Grid2 container sx={{ flexGrow: 1 }}>
                                        <>
                                            <Grid2 container sx={{ flexGrow: 1 }} justifyContent="center">
                                                <Grid2 size={{ md: 4, xs: 12 }}>
                                                    <>
                                                        <Grid2 container>
                                                            <Grid2 size={{ xs: 12 }}>
                                                                <StyledPrimaryText>
                                                                    {displayNameMappings[client] || ''}
                                                                </StyledPrimaryText>
                                                            </Grid2>
                                                            <Grid2 size={{ xs: 12 }}>
                                                                <StyledSubText>
                                                                    Ages {findAgeLimit(client, 'MIN')}-
                                                                    {findAgeLimit(client, 'MAX')}
                                                                </StyledSubText>
                                                            </Grid2>
                                                        </Grid2>
                                                    </>
                                                </Grid2>
                                                <Grid2 size={{ xs: 12, md: 4 }}>
                                                    <ControllerComponent
                                                        value={internalState[client]}
                                                        handleOnChanges={(v) => {
                                                            const { MINOR = 0, ADULT = 0 } = internalState;
                                                            const companions = {
                                                                MINOR,
                                                                ADULT,
                                                                [client]: v
                                                            };

                                                            const totalCompanions = [];
                                                            Object.keys(companions).map((k) => {
                                                                Array(companions[k])
                                                                    .fill(true)
                                                                    .map(() => totalCompanions.push(k));
                                                            });

                                                            setInternalState((s) => ({
                                                                ...s,
                                                                [client]: v,
                                                                totalCompanions
                                                            }));
                                                        }}
                                                        max={
                                                            client === 'ADULT' && state?.groupSize !== 'WC'
                                                                ? config?.paramValueN - 1
                                                                : config?.paramValueN || 0
                                                        }
                                                    />
                                                </Grid2>
                                            </Grid2>
                                        </>
                                    </Grid2>
                                </Grid2>
                            );
                        })
                    )}
                <Grid2 size={{ xs: 12 }}>
                    <ButtonComponent handleClick={handleSubmit} color="#fff" background="#ff6002" label="CONFIRM" />
                </Grid2>
            </StyledContainer>
        </ModalComponent>
    );
}

export default TravellersModal;
