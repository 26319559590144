import React from 'react';
import { Grid2, Typography } from '@mui/material';

function FooterView() {
    return (
        <>
            <Grid2 container alignContent="center" justifyContent="center" spacing={5} paddingY="20px" marginTop="20px" id="footer">
                <Grid2 size={{ xs: 11, md: 8 }} marginY="10px">
                    <>
                        <Grid2
                            container
                            flexGrow={1}
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            spacing={7}
                        >
                            <Grid2>
                                <img src={require('./../../../assets/images/bpims.png')} />
                            </Grid2>
                            <Grid2>
                                <img src={require('./../../../assets/images/ayala.png')} />
                            </Grid2>
                        </Grid2>
                    </>
                </Grid2>

                <Grid2 size={{ xs: 11, md: 8 }}>
                    <Typography color="#A7A9AC" textAlign="center" fontSize="18px">
                        Powered by BPI/MS Insurance Corporation, a joint venture between <br /> the Bank of the
                        Philippine Islands and Mitsui Sumitomo Insurance Corporation of Japan.
                    </Typography>
                </Grid2>
                {/* <Grid2 size={{ xs: 11, md: 8 }}>
                    <Typography color="#A7A9AC" textAlign="center" fontSize="18px">
                        Policy terms and conditions apply. <br /> BPI/MS Insurance Corporation's products are non-life
                        insurance products. These
                        products are not covered <br /> by the Philippine Deposit Insurance Corporation.
                    </Typography>
                </Grid2> */}

                <Grid2 size={{ xs: 11, md: 8 }}>
                    <Typography color="#A7A9AC" textAlign="center" fontSize="18px">
                        For concerns, you may call BPI MS at <a href="tel:63288409000" style={{ color: '#A7A9AC'}}>(632) 8840 9000</a> or send an e-mail to helpline@bpims.com.{' '}
                        <br /> Our representatives will be glad to assist you.
                    </Typography>
                </Grid2>

                <Grid2 size={{ xs: 11, md: 8 }}>
                    <Typography color="#A7A9AC" textAlign="center" fontSize="18px">
                        <strong>Got a medical emergency?</strong>
                    </Typography>
                    <Typography color="#A7A9AC" textAlign="center" fontSize="18px">
                        <strong>Call BPI MS Travel Assistance at <a href="tel:+63282313922" style={{ color : '#A7A9AC'}}>+63 2 8231 3922</a>.</strong>
                    </Typography>
                </Grid2>

                <Grid2 size={{ xs: 11, md: 8 }}>
                    <Typography
                        color="#A7A9AC"
                        textAlign="center"
                        fontSize="16px"
                    >
                        BPI/MS Insurance Corporation is regulated by the Insurance Commission (IC).
                    </Typography>
                    <Typography
                        color="#A7A9AC"
                        textAlign="center"
                        fontSize="16px"
                    >
                        For more details visit https://www.insurance.gov.ph.
                    </Typography>
                    <Typography
                        color="#A7A9AC"
                        textAlign="center"
                        fontSize="16px"
                    >
                        IC Public Assistance Office contact details:
                    </Typography>
                    <Typography
                        color="#A7A9AC"
                        textAlign="center"
                        fontSize="16px"
                    >
                        Landline: (02) 8523-8461 loc. 103/127
                    </Typography>
                    <Typography
                        color="#A7A9AC"
                        textAlign="center"
                        fontSize="16px"
                    >
                        SMS: 09171160007 or 09999930637
                    </Typography>
                    <Typography
                        color="#A7A9AC"
                        textAlign="center"
                        fontSize="16px"
                    >
                        E-mail: publicassistance@insurance.gov.ph
                    </Typography>
                    <Typography
                        color="#A7A9AC"
                        textAlign="center"
                        fontSize="16px"
                    >
                        Offices: https://www.insurance.gov.ph/contact-us/
                    </Typography>
                </Grid2>
            </Grid2>
        </>
    );
}

export default FooterView;
