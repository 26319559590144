import React from 'react';
import { Grid2, Typography } from '@mui/material';
import styled from 'styled-components';
import { enUS } from 'date-fns/locale';
import { Calendar } from 'react-date-range';
import moment from 'moment';

import { InputComponent } from '..';
import { ReactComponent as CalendarIcon } from './../../../assets/icons/calendar.svg';
import { ModalComponent } from '../../utilities';

const StyledWrapper = styled.div`
    position: relative;
`;

const StyledIconContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
`;

const StyledIcon = styled(CalendarIcon)`
    color: #2146ad;
    height: 14px;
    width: 14px;
`;

function SelectComponent(props) {
    const {
        placeholder,
        handleOnChanges = () => {},
        value,
        disabled = false,
        min = new Date(),
        max = new Date(),
        error
    } = props;

    const [isActive, setIsActive] = React.useState(false);

    function handleSelectComponent() {
        if (disabled) return;
        setIsActive(true);
    }

    function handleSelect(arg) {
        handleOnChanges(arg);
        setIsActive(!isActive);
    }

    return (
        <>
            <StyledWrapper onClick={handleSelectComponent}>
                <InputComponent
                    disabled
                    textAlign="left"
                    hideDecoration
                    rounderCorners
                    $inactive
                    placeholder={value || placeholder}
                />
                <StyledIconContainer>
                    <StyledIcon />
                </StyledIconContainer>
            </StyledWrapper>

            {error && (
                <Grid2 size={{ xs: 12 }}>
                    <Typography marginLeft="5px" fontSize="14px" color="error">
                        {error}*
                    </Typography>
                </Grid2>
            )}

            <ModalComponent open={isActive} handleClose={() => setIsActive(false)}>
                <Calendar
                    date={
                        value && moment(new Date(value)).isBetween(new Date(max), new Date(min))
                            ? new Date(value)
                            : new Date(max)
                    }
                    locale={enUS}
                    minDate={new Date(min)}
                    maxDate={new Date(max)}
                    onChange={handleSelect}
                />
            </ModalComponent>
        </>
    );
}

export default SelectComponent;
