import moment from 'moment';

function getAgeLimit(clientType, maintenance) {
    try {
        // limit
        const parameterMin = (maintenance?.parameters || []).find(
            (f) => f.paramName === `CLIENT.CATEGORY.${clientType}.AGE.MIN`
        );

        // limit
        const parameterMax = (maintenance?.parameters || []).find(
            (f) => f.paramName === `CLIENT.CATEGORY.${clientType}.AGE.MAX`
        );

        if (!parameterMax || !parameterMin) throw new Error('config not found');

        const max = new Date(moment().clone().subtract(parameterMin.paramValueN, 'years'));
        const min = new Date(moment().clone().subtract(parameterMax.paramValueN, 'years'));

        return { min, max };
    } catch (error) {
        return { min: 0, max: 0 };
    }
}

export default getAgeLimit;
