import react from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDropDown } from '@mui/icons-material';

import { InputComponent } from '..';
import { Grid2 } from '@mui/material';

const StyledWrapper = styled.div`
    position: relative;
    cursor: pointer;
`;

const StyledIconContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #002444;
    height: 100%;
`;

const StyledIcon = styled(ArrowDropDown)`
    color: #fff;
    font-size: xxx-large !important;
`;

const StyledMenuContainer = styled(Grid2)`
    z-index: 1;
    position: absolute;
    top: 110%;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.5);
    max-height: 200px;
    overflow: auto;
`;

const StyledSelection = styled(Grid2)`
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background: #fff;
    text-align: left;
    padding-left: 25%;

    &:hover {
        background: #d9d9d9 !important;
    }
`;

function DropdownComponent(props) {
    const { placeholder, selections = [], handleOnChanges = () => {}, value, staticSelection } = props;
    const wrapperRef = react.useRef(null);

    const [isActive, setIsActive] = react.useState(false);
    const [internalSelections, setInternalSelections] = react.useState([]);

    react.useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsActive(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    react.useEffect(() => {
        if (staticSelection) {
            const _selection = (selections || []).slice();
            if (_selection) _selection.unshift(staticSelection);

            setInternalSelections(_selection);
        } else {
            const _selection = (selections || []).slice();

            setInternalSelections(_selection);
        }
    }, [staticSelection, selections]);

    function handleDropdownComponent() {
        setIsActive(!isActive);
    }

    function handleSelectItem(val) {
        handleOnChanges(val);
        setIsActive(false);
    }

    return (
        <StyledWrapper ref={wrapperRef} onClick={handleDropdownComponent}>
            <InputComponent value={value} hideDecoration $inactive placeholder={placeholder} />
            <StyledIconContainer>
                <StyledIcon />
            </StyledIconContainer>
            {!!isActive && !!selections.length && (
                <StyledMenuContainer>
                    {internalSelections.map((selection, index) => (
                        <StyledSelection
                            key={`${uuidv4()}-select-item-${index}`}
                            size={{ xs: 12 }}
                            onClick={() => handleSelectItem(selection.value)}
                        >
                            {selection.label}
                        </StyledSelection>
                    ))}
                </StyledMenuContainer>
            )}
        </StyledWrapper>
    );
}

export default DropdownComponent;
