import { Button, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledText = styled(Typography)`
    font-size: 12px !important;
    font-weight: 500 !important;
`;

const StyledButton = styled(Button)`
    background: ${(props) => props.$background} !important;
    border-radius: 8px !important;
    width: 100%;
    color: ${(props) => props.$color} !important;
    // border-color: rgba(0, 0, 0, 0.7) !important;
    border-color: transparent;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
`;

function ButtonComponent(props) {
    const { label, background = '#edf1f6', color = '#000', handleClick = () => {}, disabled = false } = props;
    return (
        <StyledButton
            variant="outlined"
            $background={background}
            $color={color}
            onClick={handleClick}
            disabled={disabled}
        >
            <StyledText>{label}</StyledText>
        </StyledButton>
    );
}

export default ButtonComponent;
