import BaseRequest from './base';

function MaintenanceService(tranxRef) {
    const baseUrl = process.env.REACT_APP_API_URL;
    return BaseRequest(`${baseUrl}/travel/payment/get`, {
        tranxRef
    });
}

export default MaintenanceService;
