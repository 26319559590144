import React from 'react';
import { Backdrop, Box, Modal, Fade } from '@mui/material';
import styled from 'styled-components';

const StyledBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 24;
`;

function ModalComponent(props) {
    const { open = false, children = null, handleClose = () => {}, ...others } = props;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500
                }
            }}
        >
            <Fade in={open}>
                <StyledBox {...others}>{children}</StyledBox>
            </Fade>
        </Modal>
    );
}

export default ModalComponent;
