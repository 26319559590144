import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Grid2 } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import { InputComponent } from '..';

const StyledWrapper = styled.div`
    position: relative;
`;

const StyledIconContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
`;

const StyledIcon = styled(ArrowDropDown)`
    color: #013462;
    font-size: xx-large !important;
`;

const StyledMenuContainer = styled(Grid2)`
    position: absolute;
    z-index: 1 !important;
    top: 110%;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.5);
    max-height: 30vh;
    overflow-x: hidden;
    overflow-y: auto;
`;

const StyledSelection = styled(Grid2)`
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background: #fff;
    text-align: left;
    padding-left: 25%;

    &:hover {
        background: #d9d9d9 !important;
    }
`;

function SelectComponent(props) {
    const {
        placeholder,
        selections = [],
        handleOnChanges = () => {},
        value = '',
        handleOnClick = undefined,
        disabled = false,
        hideDecoration = false,
        rounderCorners = false,
        error
    } = props;
    const wrapperRef = React.useRef(null);

    const [isActive, setIsActive] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsActive(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    React.useEffect(() => {
        if (value) {
            const _selection = selections.find((selection) => selection.value === value);
            setSelectedItem(() => _selection);
        } else {
            setSelectedItem(() => ({}));
        }

        setIsActive(false);
    }, [value]);

    function handleSelectComponent() {
        if (disabled) return;
        if (handleOnClick) return handleOnClick();
        setIsActive(!isActive);
    }

    function handleSelectItem(arg) {
        handleOnChanges(arg);
    }

    return (
        <StyledWrapper ref={wrapperRef} onClick={handleSelectComponent}>
            <InputComponent
                disabled
                hideDecoration={hideDecoration}
                rounderCorners={rounderCorners}
                $inactive
                placeholder={selectedItem?.label || placeholder}
                error={error}
            />
            <StyledIconContainer>
                <StyledIcon />
            </StyledIconContainer>
            {!!isActive && !!selections.length && (
                <StyledMenuContainer>
                    {selections.map((selection, index) => (
                        <StyledSelection
                            key={`${uuidv4()}-select-item-${index}`}
                            size={{ xs: 12 }}
                            onClick={() => handleSelectItem(selection.value)}
                        >
                            {selection.label}
                        </StyledSelection>
                    ))}
                </StyledMenuContainer>
            )}
        </StyledWrapper>
    );
}

export default SelectComponent;
