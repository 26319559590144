import React from 'react';
import ReactDOM from 'react-dom/client';
import SPA from './spa';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './services';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <AppProvider>
            <SPA />
        </AppProvider>
    </React.StrictMode>
);

reportWebVitals();
