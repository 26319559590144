import React from 'react';
import styled from 'styled-components';
import { Grid2, Typography } from '@mui/material';

import { ReactComponent as UploadIcon } from './../../../assets/icons/upload.svg';
import CheckIcon from '@mui/icons-material/Check';

const StyledContainer = styled(Grid2)`
    border-radius: ${(props) => (props.$hideDecoration ? (props.$rounderCorners ? '8px' : '0') : '8px')} !important;
    border: solid 1px rgba(0, 0, 0, 0.8);
    overflow: hidden;
    cursor: pointer;
    padding: 10px;
    background-color: #edf1f6;
`;

const UploadButton = styled.label`
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    color: #013462;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: center;

    svg {
        margin-right: 8px;
    }
`;

const HiddenInput = styled.input`
    // display: none;
    z-index: -999;
    max-height: 0px;
    max-width: 0px;
    opacity: 0;
`;

function FileUpload(props) {
    const { handleOnChanges = () => {}, id, error, fileUploaded } = props;
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleOnChanges(file);
        }
    };

    return (
        <>
            <StyledContainer $hideDecoration $rounderCorners container padding={0} gap={0}>
                <UploadButton htmlFor={id}>
                    {fileUploaded ? (
                        <>
                            <CheckIcon color="success" />
                            File uploaded
                        </>
                    ) : (
                        <>
                            <UploadIcon color="#013462" />
                            Upload file
                        </>
                    )}
                </UploadButton>
                <HiddenInput type="file" id={id} onChange={handleFileChange} accept="image/*" />
            </StyledContainer>
            {error && (
                <Grid2 size={{ xs: 12 }}>
                    <Typography marginLeft="5px" fontSize="14px" color="error">
                        {error}*
                    </Typography>
                </Grid2>
            )}
        </>
    );
}

export default FileUpload;
