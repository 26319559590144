import React from 'react';
import { Grid2, Typography, styled } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { ButtonComponent, ModalComponent } from '../..';

const StyledContainer = styled(Grid2)`
    padding: 50px !important;
`;

const StyledIcon = styled(InfoIcon )`
    font-size: 68px !important;
`;

function ClaimModal(props) {
    const { handleClose = () => {} } = props;

    return (
        <ModalComponent
            open={true}
            handleClose={handleClose}
            sx={{
                maxWidth: {
                    xs: '80vw',
                    md: '30vw'
                },
                minWidth: {
                    xs: '95vw',
                    md: '30vw'
                },
                maxHeight: {
                    xs: '85vh',
                    md: '70vh'
                },
                overflow: {
                    xs: 'auto',
                    md: 'auto'
                }
            }}
        >
            <StyledContainer container rowSpacing={2}>
                <Grid2 size={{ xs: 12 }} display="flex" justifyContent="center">
                    <StyledIcon />
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <Typography textAlign="center">You may file a claim with us by</Typography>
                    <Typography textAlign="center">
                        e-mailing{' '}
                        <a href="mailto:BPIMS_Non Motor_Claims@bpi.com.ph">BPIMS_Non Motor_Claims@bpi.com.ph</a>
                    </Typography>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <ButtonComponent handleClick={handleClose} label="BACK" />
                </Grid2>
            </StyledContainer>
        </ModalComponent>
    );
}

export default ClaimModal;
