import React from 'react';
import styled from 'styled-components';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { enUS } from 'date-fns/locale';
import { DateRange } from 'react-date-range';
import { Divider, Grid2, Typography } from '@mui/material';
import { ReactComponent as CalendarIcon } from './../../../assets/icons/calendar.svg';
import { ModalComponent } from '../..';
import moment from 'moment';

const StyledContainer = styled(Grid2)`
    border: solid 1px rgba(0, 0, 0, 0.7) !important;
    border-radius: 8px !important;
    background: #edf1f6;
    cursor: pointer;
`;

const StyledMuteText = styled(Typography)`
    font-size: 6px !important;
    font-weight: 500 !important;
    padding-top: 5px;
`;

const StyledText = styled(Typography)`
    font-size: 12px !important;
    font-weight: 500 !important;
    padding-bottom: 5px;
`;

const StyledIcon = styled(CalendarIcon)`
    color: #000;
    font-size: xxx-large !important;
`;

function DaterangeComponent(props) {
    const { startDate, endDate, handleChange = () => {}, maxRange = 0 } = props;
    const [isActive, setIsActive] = React.useState(false);

    return (
        <>
            <StyledContainer container justifyContent="center" onClick={() => setIsActive(!isActive)}>
                <Grid2
                    size={{ xs: 12, md: 5 }}
                    sx={{
                        padding: {
                            xs: '10px',
                            md: '0'
                        }
                    }}
                >
                    <Grid2 container>
                        <Grid2 size="grow">
                            <Grid2 container direction="column" paddingLeft={1}>
                                <Grid2>
                                    <StyledMuteText>From</StyledMuteText>
                                </Grid2>
                                <Grid2>
                                    <StyledText>
                                        {startDate
                                            ? moment(new Date(startDate)).format('MM | DD | YY')
                                            : 'MM | DD | YY'}
                                    </StyledText>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Grid2 size={{ xs: 2 }} display="flex" alignItems="center">
                            <StyledIcon />
                        </Grid2>
                    </Grid2>
                </Grid2>

                <Grid2
                    size={{ md: 1 }}
                    display="flex"
                    justifyContent="center"
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'flex'
                        }
                    }}
                >
                    <Divider textAlign="center" orientation="vertical" />
                </Grid2>

                <Grid2
                    size={{ xs: 12, md: 5 }}
                    sx={{
                        padding: {
                            xs: '10px',
                            md: '0'
                        }
                    }}
                >
                    <Grid2 container>
                        <Grid2 size="grow">
                            <Grid2 container direction="column" paddingLeft={1}>
                                <Grid2>
                                    <StyledMuteText>To</StyledMuteText>
                                </Grid2>
                                <Grid2>
                                    <StyledText>
                                        {endDate ? moment(new Date(endDate)).format('MM | DD | YY') : 'MM | DD | YY'}
                                    </StyledText>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Grid2 size={{ xs: 2 }} display="flex" alignItems="center">
                            <StyledIcon />
                        </Grid2>
                    </Grid2>
                </Grid2>
            </StyledContainer>
            <ModalComponent open={isActive && maxRange} handleClose={() => setIsActive(false)}>
                <DateRange
                    editableDateInputs={false}
                    onChange={(item) => {
                        handleChange(item.selection);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={[
                        {
                            startDate: startDate ? new Date(startDate) : new Date(),
                            endDate: endDate ? new Date(endDate) : new Date(),
                            key: 'selection'
                        }
                    ]}
                    locale={enUS}
                    minDate={new Date()}
                    {...(startDate && { maxDate: new Date(moment(new Date(startDate)).add(maxRange, 'days')) })}
                />
            </ModalComponent>
        </>
    );
}

export default DaterangeComponent;
