import React from 'react';
import numeral from 'numeral';
import { Grid2, Typography } from '@mui/material';

import QuotationScaffold from '../../scaffold/quotation';

import { AppContext } from '../../../services';

function QuotationView() {
    const { state } = React.useContext(AppContext);

    return typeof state.premAmount === 'number' ? (
        <Grid2 container direction="column" alignContent="center">
            <Grid2 size={{ xs: 8 }} sx={{ background: '#013462', padding: '60px 0', marginTop: '24px' }}>
                <Typography color="white" textAlign="center" fontSize="24px">
                    Premium per Traveler
                </Typography>
                <br />
                <Typography color="white" textAlign="center" fontSize="40px" fontWeight={800}>
                    <strong>PHP {numeral(state.premAmount ?? 0).format('0,0.00')}</strong>
                </Typography>
            </Grid2>
            <Grid2 size={{ xs: 8 }}>
                <QuotationScaffold />
            </Grid2>
        </Grid2>
    ) : null;
}

export default QuotationView;
